<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" header="File Download"
    position="top" style="max-width: 80%; min-width: 60%; max-height: 80%;">
    <p>Start the Download for the following file(s)?</p>
    <div v-for="file in files" :key="file">
      <ConTldr :text="file">
      </ConTldr>
    </div>
    <template #footer>
      <Button class="p-button-text" label="Cancel" @click="() => { dialogVisible = false; }" />
      <Button label="Download" outlined @click="onDownload" autofocus />
    </template>
  </Dialog>

</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { ref, watch } from 'vue';
import Button from 'primevue/button';
import { StorageService } from '@/apis/aready-api';
import { ConTldr } from '@congatec/primevue-components-lib'

const dialogVisible = ref(false);

const props = defineProps<{
  visible: boolean,
  files: any,
  onHide: (created: boolean) => any
}>();

//watch area
watch(() => props.visible, async (nv: any) => {
  console.log("VISIBLE: ", nv);
  console.log("files: ", props.files);
  if (nv != undefined) {
    dialogVisible.value = nv;
  }
  //   if (props.files) {

  //   }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    props.onHide(true);
  }
})

const onDownload = async () => {
  //const myWorker = new Worker("/FileDownloadWorker.js");
  
  console.log("files: ", props.files);
  if (props.files != null) {
    for(let file of props.files) {
      let result = await StorageService.getApiV1StorageFileGetUrl(file);
  
      window.open(result.objectUrl!);

    }
    // const handle = await (window as any).showSaveFilePicker();
    
    // const writer = handle.createWriteable();
    // let result = await StorageService.getApiV1StorageFileGet(props.files[0]);
    // let response = await axios({
    //   url: `${AReadyOpenApi.BASE}/api/v1/storage/File/Get?Path=${encodeURIComponent(props.files[0])}`,
    //   method: 'GET',
    //   responseType: 'stream'
    // });
    // const handle = await (window as any).showDirectoryPicker();
    // myWorker.postMessage({handle: handle, url: `${AReadyOpenApi.BASE}/api/v1/storage/File/Get?Path=${encodeURIComponent(props.files[0])}`});

    // console.log(response.headers);
    // const blob = new Blob([response.data]);
    // const fileName = response.headers['filename'];

    // const downloadLink = document.createElement('a');
    // downloadLink.href = URL.createObjectURL(blob);
    // downloadLink.download = fileName; // set the file name here
    // downloadLink.click();

    dialogVisible.value = false;
  }

}

</script>
