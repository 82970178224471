<template>
  <ConfirmDialog />
  <Toast position="center" group="center" />
  <InputParticipant :visible="showInputParticipant" :onHide="onInputParticipantClose"
    :participant="selectedParticipants[0]" :rfidCode="scannedRfIdCode" />
  <div class="m-5 mt-7">
    <ConDataTable tableId="ParticipantsList" title="Participants" :columns="participantTableColumns" filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchDataAsync" @onSelectionChanged="handleSelectionChange"
      ref="participantTableRef" :createActionMenu="createActionMenu" globalFilter="firstName|lastName|email" exportable export-filename="congatec-Ultramarathon-Participants">

    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { ConDataTable } from '@congatec/primevue-components-lib';
import { ParticipantsService } from '@/apis/lapcounter-api/services/ParticipantsService';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';
import { LapsService } from '@/apis/lapcounter-api/services/LapsService';
import type { LapCreateRequest } from '@/apis/lapcounter-api/models/LapCreateRequest';
import onScan from "onscan.js";
import InputParticipant from './InputParticipant.vue';
import { onBeforeRouteLeave } from 'vue-router';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import router from "@/globalRouter";
//import { ProductService } from '@/service/ProductService';

//const participants = ref([{ id: 1, firstname: "Lukas", lastname: "Krickl", email: "lukas.krickl@congatec.com", completedlaps: 5 }]);
const selectedParticipants = ref<Array<any>>([]);
const participantTableRef = ref<null | typeof ConDataTable>(null);
const toast = useToast();
const confirm = useConfirm();
const showInputParticipant = ref(false);
const scannedRfIdCode = ref("");
let scanTime = new Date();

const participantTableColumns = [
  // {
  //   selectionMode: 'multiple',
  //   field: 'any'
  // },
  {
    field: "id",
    header: "ID",
    dataType: "text",
    isFiltered: false, // not filter-/sortable because not yet implemented in Data-Lib
    sortable: false,
  },
  {
    field: "firstName",
    header: "First Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "lastName",
    header: "Last Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "rfidCode",
    header: "Rfid Code",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  // {
  //   field: "email",
  //   header: "E-Mail",
  //   dataType: "text",
  //   isFiltered: true,
  //   sortable: true,
  // },
  {
    field: "lapCount",
    header: "Completed Laps",
    dataType: "numeric",
    isFiltered: true, // TODO not yet working: probably need custom sieve filter
    sortable: true,
  },
  {
    field: "actionMenu",
  }
]

onMounted(() => {
  //ProductService.getProductsMini().then((data) => (products.value = data));


});

initOnScan();

function initOnScan() {
  if (!onScan.isAttachedTo(document)) {
    console.log("initOnScan")
    // Initialize onScan with options
    onScan.attachTo(document, {
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      //reactToKeydown: true,
      avgTimeByChar: 50,
      onScan: function (sCode: string, iQty: string) {
        console.log('Scanned: ' + iQty + 'x ' + sCode);
        //toast.add({ severity: 'success', summary: 'Scan successful', detail: 'Lap completed', life: 3000 });
        codeScanned(sCode);

      },
      onKeyDetect: function (iKeyCode: string) {
        console.log('Pressed: ' + iKeyCode);
      }
    });
  }
}

async function codeScanned(rfidCode: string) {
  if (rfidCode == scannedRfIdCode.value && new Date().getTime() - scanTime.getTime() < 10000) {
    toast.add({ severity: 'warn', summary: 'Incorrect scan', detail: `Double scan detected.`, life: 3000 });
    return;
  }
  scanTime = new Date();
  scannedRfIdCode.value = rfidCode;

  let res = await ParticipantsService.getApiV1ParticipantsRfid(rfidCode);

  if (res != null) {
    let request: LapCreateRequest = {
      firstName: null,
      lastName: null,
      email: null,
      rfidCode: rfidCode, // TODO in case we need rfid-codes
      manualCompletion: false,
    }
    await LapsService.postApiV1Laps(request);
    toast.add({ severity: 'success', summary: 'Completed', detail: `Lap ${res.lapCount! + 1} completed for ${res.firstName} ${res.lastName}`, life: 5000, group: "center" });
    reloadTableData();
  }
  else {
    console.log('no Response from getApiV1ParticipantsRfid');
    selectedParticipants.value = [];
    onScan.detachFrom(document);
    showInputParticipant.value = true;
  }
}

async function fetchDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {
  let res = await ParticipantsService.getApiV1Participants(filters, sorts, page, rows);
  console.log("Resolution: ", res)
  return {
    totalCount: res.totalCount,
    data: res.participants,
  }
}

function handleSelectionChange(selectedRows: any) {
  console.log("handleSel", selectedRows)
}


function createActionMenu(row: any): any {
  return [
    {
      label: 'Create',
      icon: 'pi pi-plus',
      command: () => {
        //selectedVisitorBackup = selectedParticipants.value;
        selectedParticipants.value = [];
        scannedRfIdCode.value = '';
        onScan.detachFrom(document);
        showInputParticipant.value = true;
      }
    },
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        //selectedVisitorBackup = selectedParticipants.value;
        selectedParticipants.value = [];
        selectedParticipants.value.push(row);
        scannedRfIdCode.value = '';
        onScan.detachFrom(document);
        showInputParticipant.value = true;
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => deleteParticipant(row)
    },
    {
      label: 'Complete Lap',
      icon: 'pi pi-check-circle',
      command: async () => await completeLap(row)
    },
    {
      label: 'Lap Details',
      icon: 'pi pi-history',
      command: () =>  router.push({
          path: "/lapcounter/lap-details/" + row.id,
        })
    }
  ];
}

function deleteParticipant(row: any) {
  //console.log('VisitorId: ', row.id);
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item',
    accept: async () => {
      try {
        await ParticipantsService.deleteApiV1Participants({ id: row.id });
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item deleted', life: 3000 });

        reloadTableData();
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
    }
  })
}

function reloadTableData() {
  participantTableRef.value?.forceReload();
}

const completeLap = async (row: any) => {
  try {
    let request: LapCreateRequest = {
      firstName: row.firstName,
      lastName: row.lastName,
      email: row.email,
      rfidCode: null, // TODO in case we need rfid-codes
      manualCompletion: true,
    }
    await LapsService.postApiV1Laps(request);
    toast.add({ severity: 'success', summary: 'Completed', detail: 'Lap completed', life: 3000 });

    reloadTableData();
  } catch (ex: any) {
    toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
  }
}

const onInputParticipantClose = () => {
  console.log("reloadTableData");
  initOnScan();
  showInputParticipant.value = false;
  reloadTableData();
}

onBeforeRouteLeave((to, from) => {
  onScan.detachFrom(document);
})

</script>
<style>

/* .p-toast-center {
    min-width: 20vw;
    transform: translate(-50%, -50%);
    width: 25rem;
    height: 25rem;
} */

.customToast {
  width: 25rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}



</style>