/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateQrCodeResponse } from '../models/CreateQrCodeResponse';
import type { CreateVisitorRequest } from '../models/CreateVisitorRequest';
import type { CreateVisitorResponse } from '../models/CreateVisitorResponse';
import type { DeleteVisitorRequest } from '../models/DeleteVisitorRequest';
import type { DeleteVisitorResponse } from '../models/DeleteVisitorResponse';
import type { EditVisitorRequest } from '../models/EditVisitorRequest';
import type { EditVisitorResponse } from '../models/EditVisitorResponse';
import type { ListVisitorResponse } from '../models/ListVisitorResponse';
import type { ScreenVisitorResponse } from '../models/ScreenVisitorResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VisitorService {

    /**
     * @param requestBody 
     * @returns CreateVisitorResponse Success
     * @throws ApiError
     */
    public static postApiV1VisitorCreate(
requestBody?: CreateVisitorRequest,
): CancelablePromise<CreateVisitorResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Visitor/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditVisitorResponse Success
     * @throws ApiError
     */
    public static putApiV1VisitorEdit(
requestBody?: EditVisitorRequest,
): CancelablePromise<EditVisitorResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Visitor/Edit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteVisitorResponse Success
     * @throws ApiError
     */
    public static deleteApiV1VisitorDelete(
requestBody?: DeleteVisitorRequest,
): CancelablePromise<DeleteVisitorResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Visitor/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListVisitorResponse Success
     * @throws ApiError
     */
    public static getApiV1VisitorList(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListVisitorResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Visitor/List',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param visitorId 
     * @returns ScreenVisitorResponse Success
     * @throws ApiError
     */
    public static putApiV1VisitorScreen(
visitorId: number,
): CancelablePromise<ScreenVisitorResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Visitor/{VisitorId}/screen',
            path: {
                'VisitorId': visitorId,
            },
        });
    }

    /**
     * @param id 
     * @returns CreateQrCodeResponse Success
     * @throws ApiError
     */
    public static getApiV1VisitorQr(
id?: number,
): CancelablePromise<CreateQrCodeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Visitor/qr',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param eventId 
     * @returns ListVisitorResponse Success
     * @throws ApiError
     */
    public static getApiV1VisitorFindByEventId(
eventId?: string,
): CancelablePromise<ListVisitorResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Visitor/FindByEventId',
            query: {
                'EventId': eventId,
            },
        });
    }

}
