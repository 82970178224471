<template>
    <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
        position="top" style="max-width: 80%; min-width: 80%; max-height: 80%;">
        <template #default>
            <div class="flex flex-column w-full gap-5">
                <div class="flex flex-row w-full gap-8">
                    <div class="flex flex-column w-4 gap-1">
                        <label>Rfid Code</label>
                        <InputText :disabled="props.rfidCode != ''" type="text" v-model="rfidCode" placeholder="Rfid Code of the Participant" />
                    </div>
                </div>
                <div class="flex flex-row w-full gap-8">
                    <div class="flex flex-column w-4 gap-1">
                        <label>First Name</label>
                        <InputText type="text" v-model="firstName" placeholder="First name of the Participant" />
                    </div>
                    <div class="flex flex-column w-4 gap-1">
                        <label>Last Name</label>
                        <InputText type="text" v-model="lastName" placeholder="Last name of the Participant" />
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex flex-row align-items-end justify-content-between">
                <Button class="p-button-text" label="Cancel" :onClick="() => {
                    dialogVisible = false;
                }" />

                <!-- <ConMessages class=""
          v-if="v$.Firstname.$invalid || v$.Lastname.$invalid || v$.Email.$invalid || v$.Country.$invalid"
          severity="error">
          <template #messageContent>
              {{ v$.$errors[0].$property + " " + v$.$errors[0].$message }}
          </template>
        </ConMessages> -->

                <Button class="p-button-primary" label="Save" :onClick="async () => {
                    if (props.participant) {
                        await dispatchEditParticipantAsync({
                            id: props.participant.id,
                            firstname: firstName,
                            lastname: lastName,
                            rfidCode: rfidCode,
                        } as ParticipantEditRequest)
                        return;
                    }

                    await dispatchCreateParticipantAsync({
                        firstname: firstName,
                        lastname: lastName,
                        email: null,
                        rfidCode: rfidCode,
                    } as ParticipantCreateRequest)
                }" />
            </div>
        </template>
    </Dialog>
</template>
<script setup lang="ts">
import InputText from 'primevue/inputtext';
import { ref, watch } from 'vue';
import { ParticipantsService } from '@/apis/lapcounter-api/services/ParticipantsService';
import type { ParticipantCreateRequest } from '@/apis/lapcounter-api/models/ParticipantCreateRequest';
import { LapsService } from '@/apis/lapcounter-api/services/LapsService';
import type { LapCreateRequest } from '@/apis/lapcounter-api/models/LapCreateRequest';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog'
import type { ParticipantEditRequest } from '@/apis/lapcounter-api/models/ParticipantEditRequest';

const props = defineProps<{
    participant?: any,
    rfidCode: string,
    visible: boolean,
    onHide: (edited: boolean) => any
}>()

const toast = useToast();
const headerText = ref("Create new Participant");
const dialogVisible = ref(false);
const firstName = ref('');
const lastName = ref('');
const rfidCode = ref(props.rfidCode);

watch(() => props.visible, (nv) => {
  console.log("VISIBLE: ", nv);
  if (nv) {
    dialogVisible.value = nv;
    rfidCode.value = props.rfidCode;
  }

  if (nv && props.participant ) {
    headerText.value = "Edit Participant"
    rfidCode.value = props.participant.rfidCode;
    firstName.value = props.participant.firstName;
    lastName.value = props.participant.lastName;
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    headerText.value = "Create new Participant"
    rfidCode.value = "";
    firstName.value = "";
    lastName.value = "";
    props.onHide(true);
  }

})

async function dispatchEditParticipantAsync(editParticipantRequest: ParticipantEditRequest) {

    await ParticipantsService.putApiV1Participants(editParticipantRequest);
    //await VisitorService.putApiV1VisitorEdit(EditVisitorRequest);
    dialogVisible.value = false;
}

async function dispatchCreateParticipantAsync(createParticipantsRequest: ParticipantCreateRequest) {
    console.log("Participant Create: ", createParticipantsRequest)

    await ParticipantsService.postApiV1Participants(createParticipantsRequest)
    let request: LapCreateRequest = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: null,
        rfidCode: rfidCode.value, // TODO in case we need rfid-codes
        manualCompletion: true,
    }
    await LapsService.postApiV1Laps(request);
    toast.add({ severity: 'success', summary: 'Participant created', detail: 'Participant created successfully', life: 3000 });
    dialogVisible.value = false;
}
</script>