<template>
    <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" header="File Metadata"
        position="top" style="max-width: 80%; min-width: 60%; max-height: 80%;">
        <div class="flex flex-column gap-3">
            <div class="w-full">
                <label for="identifier">Identifier <span>*</span></label>
                <InputText id="identifier" :disabled="releaseState==2" v-model="state.Identifier" placeholder="Enter Identifier" class="w-full"
                    :class="{ 'input-invalid': v$.Identifier.$invalid && v$.Identifier.$error, 'h-3rem': true }" />
                <span v-if="v$.Identifier.$error">{{
                    v$.Identifier.$errors[0].$message }}
                </span>
            </div>
            <div class="w-full">
                <label for="PlmItem">PLM Item <span>*</span></label>
                <InputText id="PlmItem" :disabled="releaseState==2" v-model="state.PlmItem" placeholder="Enter PLM Item" class="w-full"
                    :class="{ 'input-invalid': v$.PlmItem.$invalid && v$.PlmItem.$error, 'h-3rem': true }" />
                <span v-if="v$.PlmItem.$error">{{
                    v$.PlmItem.$errors[0].$message }}
                </span>
            </div>
            <div class="flex flex-column w-full">
                <label for="description">Description</label>
                <Textarea id="description" v-model="state.Description" autoResize rows="5" />
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" label="Cancel" @click="() => { dialogVisible = false; }" />
            <Button label="Save" :disabled="releaseState==ReleaseStatus.RELEASE || state.Identifier.length == 0 || state.PlmItem.length == 0" outlined @click="onSave" autofocus />
        </template>
    </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { ref, watch } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { ReleaseStatus, StorageService, type ArtifactUpdateRequest } from '@/apis/aready-api';
import { ConTldr } from '@congatec/primevue-components-lib'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const dialogVisible = ref(false);
const guid = ref();
const releaseState = ref();

const props = defineProps<{
    visible: boolean,
    file: any,
    onHide: (created: boolean) => any
}>();

const state = ref({
    Identifier: '',
    PlmItem: '',
    Description: '',
});

const rules = {
    Identifier: { required },
    PlmItem: { required },
}
const v$ = useVuelidate(rules, state);


//watch area
watch(() => props.visible, async (nv: any) => {
    console.log("VISIBLE: ", nv);
    console.log("file: ", props.file);
    if (nv != undefined) {
        dialogVisible.value = nv;
    }
    if (nv && props.file ) {
        let res = await StorageService.getApiV1StorageArtifact(`filePath==${props.file}`, "", 1, 10)
        if(res && res.artifacts && res.artifacts[0]) {
            state.value.Identifier = res.artifacts[0]?.identifier || "";
            state.value.PlmItem = res.artifacts[0]?.plmItem || "";
            state.value.Description = res.artifacts[0]?.description || "";
            guid.value = res.artifacts[0].guid;
            releaseState.value = res.artifacts[0].state;
        }
        else {
            state.value.Identifier = "";
            state.value.PlmItem = "";
            state.value.Description = "";
            guid.value = "";
            releaseState.value = ReleaseStatus.DRAFT;
        }
    }
    else if(!nv) {
        // empty inputs on hide
        state.value.Identifier = "";
        state.value.PlmItem =  "";
        state.value.Description =  "";
    }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
    if (nv != null && !nv) {
        props.onHide(true);
    }
})

const onSave = async () => {
    console.log("onSave: ");
    let request: ArtifactUpdateRequest = {
        identifier: state.value.Identifier, plmItem: state.value.PlmItem, description: state.value.Description
    }
    await StorageService.putApiV1StorageArtifact(guid.value, request);
    dialogVisible.value = false;
}



</script>
<style scoped>
.input-invalid {
    border: 1px solid var(--primary-color);
}

.input-invalid:hover {
    border: 1px solid var(--primary-color) !important;
}

span {
    color: var(--primary-color);
    font-weight: 600;
}
</style>