import BundlesList from "../components/bundle/BundlesList.vue";
import FileUpload from "../components/FileUpload.vue";import FileList from '../components/FileList.vue'


const areadyRoutes = [
  {
    path: "/aready/file-upload",
    name: "FileUpload",
    component: FileUpload,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "FileUpload" }],
    },
  },
  {
    path: "/aready/bundles",
    name: "BundlesList",
    component: BundlesList,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "BundlesList" }],
    },
  },
  {
    path: '/aready/file-list',
    name: 'FileList',
    component: FileList,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "FileList" }],
    },
  },
];

export default areadyRoutes;
