/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArtifactCreateResponse } from '../models/ArtifactCreateResponse';
import type { ArtifactCreateStatusResponse } from '../models/ArtifactCreateStatusResponse';
import type { ArtifactListResponse } from '../models/ArtifactListResponse';
import type { ArtifactUpdateRequest } from '../models/ArtifactUpdateRequest';
import type { ArtifactUpdateResponse } from '../models/ArtifactUpdateResponse';
import type { DirectoryListResponse } from '../models/DirectoryListResponse';
import type { ObjectGetUrlResponse } from '../models/ObjectGetUrlResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StorageService {

    /**
     * @param path 
     * @param identifier 
     * @param formData 
     * @returns ArtifactCreateResponse OK
     * @throws ApiError
     */
    public static postApiV1StorageUpload(
path?: string,
identifier?: string,
formData?: {
FileData?: Blob;
},
): CancelablePromise<ArtifactCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/storage/upload',
            query: {
                'Path': path,
                'identifier': identifier,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param guid 
     * @returns ArtifactCreateStatusResponse OK
     * @throws ApiError
     */
    public static getApiV1StorageUpload(
guid: string,
): CancelablePromise<ArtifactCreateStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/storage/upload/{guid}',
            path: {
                'guid': guid,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ArtifactListResponse OK
     * @throws ApiError
     */
    public static getApiV1StorageArtifact(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ArtifactListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/storage/artifact',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param guid 
     * @param requestBody 
     * @returns ArtifactUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1StorageArtifact(
guid: string,
requestBody?: ArtifactUpdateRequest,
): CancelablePromise<ArtifactUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/storage/artifact/{guid}',
            path: {
                'guid': guid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param path 
     * @returns DirectoryListResponse OK
     * @throws ApiError
     */
    public static getApiV1StorageDirectoryList(
path?: string,
): CancelablePromise<DirectoryListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/storage/Directory/List',
            query: {
                'Path': path,
            },
        });
    }

    /**
     * @param path 
     * @returns ObjectGetUrlResponse OK
     * @throws ApiError
     */
    public static getApiV1StorageFileGetUrl(
path?: string,
): CancelablePromise<ObjectGetUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/storage/File/GetUrl',
            query: {
                'Path': path,
            },
        });
    }

}
