<template>
  <div class="flex flex-column h-full w-full">
    <ConfirmDialog />
    <div class="flex flex-row justify-content-between align-items-center w-full h-3rem">
      <h2 class="m-0">Chat History</h2>
      <Button icon="pi pi-plus" label="New Chat" text @click="$emit('new-chat')" />
    </div>
    <ScrollPanel id="congaAiSidebar" style="height:calc(100% - 3rem)">
      <div class="flex flex-column w-full h-full gap-2 px-2">
        <div class="flex flex-row align-items-center justify-content-center" v-for="(item, index) in state"
          :key="item.chatId!">
          <InputText class="border-primary text-lg p-0 py-2 pl-1 surface-card" v-if="isEditing(index)"
            v-model="item.chatName" :value="item.chatName" @keypress.enter="renameChat(item)"></InputText>
          <p class="m-0 vertical-align-middle w-full cursor-pointer text-lg " @click="emit('chat-selected', item.chatId)"
            v-else>{{ item.chatName }}</p>
          <ConKebabMenu :buttonIcon="'pi pi-ellipsis-h'" :menuItems="sidebarMenuOptions(index)" />
        </div>
      </div>
    </ScrollPanel>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { ConKebabMenu } from '@congatec/primevue-components-lib';
import InputText from 'primevue/inputtext'
import type { ChatSession } from '@/apis/azureai-api';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import Button from 'primevue/button';
import ScrollPanel from 'primevue/scrollpanel';
const emit = defineEmits(['chat-selected', 'new-chat', 'delete-chat', 'update-chatName']);
const state = ref<ChatSession[]>([])
const confirm = useConfirm();
export type ConSidebarProps = {
  items: ChatSession[];
};

const props = withDefaults(defineProps<ConSidebarProps>(), {
  items: () => [],
});

const renameChat = (item: ChatSession) => {
  if (!isNaN(item.chatId!)) {
    editingIndex.value = null;
    emit('update-chatName', item);

  } else {
    console.error('Invalid chatId:', item.chatId);
  }

};

const confirmDelete = (index: number) => {
  confirm.require({
    header: 'Are you sure?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: `This will delete ${state.value[index].chatName}`,
    accept: async () => {
      deleteChat(index);
    }
  })
}

const deleteChat = (index: number) => {
  emit('delete-chat', state.value[index].chatId);
};

const editingIndex = ref<number | null>(null);

const toggleEditing = (index: number) => {
  if (editingIndex.value === index) {
    editingIndex.value = null;
  } else {
    editingIndex.value = index;
  }
};

const isEditing = (index: number) => {
  return editingIndex.value === index;
};

const sidebarMenuOptions = (index: number) => [
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      toggleEditing(index);
    },
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => {
      confirmDelete(index);
    },
  }
];
watch((props), (nv) => {
  if (nv) {
    state.value = props.items
    state.value.forEach((chat, index) => {
      if (chat.chatName && chat.chatName.length > 50) {
        state.value[index].chatName = chat.chatName.slice(0, 25) + "...";
      }
    });
  }
})
</script>

<style>
#congaAiSidebar .p-scrollpanel-content {
  height: 100% !important;
}
</style>
