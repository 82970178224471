<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    position="top" style="max-width: 80%; min-width: 80%; max-height: 80%;">
    <template #default>
      <div class="card flex justify-content-left">
        <div class="flex flex-column  gap-5 w-full">
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="firstname">Firstname <span>*</span></label>
              <InputText id="firstname" v-model="state.Firstname" :value="state.Firstname"
                placeholder="Enter the firstname for the new Visitor" class="w-full" :class="{ 'input-invalid': v$.Firstname.$invalid && v$.Firstname.$error, 'h-3rem': true }"/>
                <span v-if="v$.Firstname.$error">{{
                v$.Firstname.$errors[0].$message }}
              </span>
            </div>
            <div class="w-full">
              <label for="lastname">Lastname <span>*</span></label>
              <InputText id="lastname" v-model="state.Lastname" placeholder="Enter the lastname for the new Visitor"
                class="w-full" :class="{ 'input-invalid': v$.Lastname.$invalid && v$.Lastname.$error, 'h-3rem': true }"/>
                <span v-if="v$.Lastname.$error">{{
                v$.Lastname.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="email">Email</label>
              <InputText id="email" v-model="state.Email" placeholder="Enter the email for the new Visitor"
                class="w-full" :class="{ 'input-invalid': v$.Email.$invalid && v$.Email.$error, 'h-3rem': true }" />
                <span v-if="v$.Email.$error">{{
                v$.Email.$errors[0].$message }}
              </span>
            </div>
            <div class="w-full">
              <label for="company">Company</label>
              <InputText id="company" v-model="company" placeholder="Enter the company for the new Visitor"
                class="w-full" />
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="postalcode">Postalcode</label>
              <InputText id="postalcode" v-model="postalcode" placeholder="Enter the postalcode for the new Visitor"
                class="w-full" />
            </div>
            <div class="w-full">
              <label for="city">City</label>
              <InputText id="city" v-model="city" placeholder="Enter the city for the new Visitor" class="w-full" />
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="street">Street</label>
              <InputText id="street" v-model="street" placeholder="Enter the street of the new Visitor" class="w-full" />
            </div>
            <div class="w-full flex flex-column">
              <label for="country">Country <span>*</span></label>
              <Dropdown id="country" filter v-model="state.Country" :options="countriesList" optionLabel="name"
                optionValue="code" placeholder="Select a Country" class="w-full md:w-14rem" :class="{ 'input-invalid': v$.Country.$invalid && v$.Country.$error, 'h-3rem': true }"/>
                <span v-if="v$.Country.$error">{{
                v$.Country.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="">
            <label>Visibility</label>
            <Dropdown v-model="access" :options="accessLevels" placeholder="Visibility" class="w-full" />
          </div>

          <div>
            <label>Substitutes</label>
            <AutoComplete dropdown class="w-full" v-model="userQuery" :suggestions="usersFound" optionLabel="identifier"
              @item-select="selectUser" />
            <MultiSelect v-model="substitutes" :options="substitutes" display="chip" placeholder="Select Substitutes"
              optionLabel="email" class="w-full mt-3" />
          </div>
        </div>


      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button class="p-button-text" label="Cancel" :onClick="() => {
          dialogVisible = false;
        }" />

        <!-- <ConMessages class=""
          v-if="v$.Firstname.$invalid || v$.Lastname.$invalid || v$.Email.$invalid || v$.Country.$invalid"
          severity="error">
          <template #messageContent>
              {{ v$.$errors[0].$property + " " + v$.$errors[0].$message }}
          </template>
        </ConMessages> -->

        <Button class="p-button-primary" label="Save" :onClick="async () => {
          if (props.visitor && !$props.copy) {
            await dispatchEditVisitorAsync({
              id: $props.visitor.id,
              firstName: state.Firstname,
              lastName: state.Lastname,
              postalCode: postalcode,
              street: street,
              city: city,
              email: state.Email,
              company: company,
              country: state.Country,
              substituteUserIds: substitutes.map((x: any) => x.id),
              accessLevel: access
            })

            return;
          }

          await dispatchCreateVisitorAsync({
            firstname: state.Firstname,
            lastname: state.Lastname,
            email: state.Email,
            company: company,
            postalcode: postalcode,
            city: city,
            street: street,
            country: state.Country,
            substituteUserIds: substitutes.map((x: any) => x.id),
            accessLevel: access
          } as Partial<CreateVisitorRequest>)
        }" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { type EditVisitorRequest, VisitorService, UserService, AccessLevel, type CreateVisitorRequest } from '@/apis/neptune-api';
import MultiSelect from 'primevue/multiselect'
import AutoComplete from 'primevue/autocomplete'
import { delay, useDelayTimer } from '@congatec/primevue-components-lib';
import Dropdown from 'primevue/dropdown';
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { countries } from "countries-list"

const props = defineProps<{
  visitor?: any,
  copy?:boolean,
  visible: boolean,
  onHide: (edited: boolean) => any
}>();

const state = ref({
  Firstname: '',
  Lastname: '',
  Email: '',
  Country: '',
});

const dialogVisible = ref(false);
// const firstname = ref("");
// const lastname = ref("");
const postalcode = ref("");
const street = ref("");
const city = ref("");
// const email = ref("");
const company = ref("");
//const country = ref("");
const substitutes = ref<any>([]);
const access = ref(AccessLevel.DEPARTMENT);

const accessLevels = [AccessLevel.DEPARTMENT, AccessLevel.PUBLIC, AccessLevel.PRIVATE];
const userQuery = ref<any>("");
const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();
let headerText = "Create a new Visitor";

//const selectedCountry = ref();
const countriesList = getCountriesList(); //getCountries();

// ref([
//     { name: 'Australia', code: 'AU' },
//     { name: 'Brazil', code: 'BR' },
//     { name: 'China', code: 'CN' },
//     { name: 'Egypt', code: 'EG' },
//     { name: 'France', code: 'FR' },
//     { name: 'Germany', code: 'DE' },
//     { name: 'India', code: 'IN' },
//     { name: 'Japan', code: 'JP' },
//     { name: 'Spain', code: 'ES' },
//     { name: 'United States', code: 'US' }
// ]);


const rules = {
  Firstname: { required },
  Lastname: { required },
  Country: { required },
  Email: { email }
  //description: { required },
}
const v$ = useVuelidate(rules, state);


watch(() => props.visible, (nv) => {
  console.log("VISIBLE: ", nv);
  console.log("Prop visitor", props.visitor)
  if (nv) {
    dialogVisible.value = nv;
  }

  if (nv && props.visitor ) {
    headerText = "Edit Visitor"
    state.value.Firstname = props.visitor.firstName;
    state.value.Lastname = props.visitor.lastName;
    postalcode.value = props.visitor.postalCode;
    street.value = props.visitor.street;
    city.value = props.visitor.city;
    state.value.Email = props.visitor.email;
    company.value = props.visitor.company;
    state.value.Country = props.visitor.country;
    substitutes.value = props.visitor.substitutes;
    access.value = props.visitor.access;
  }
  if(props.visitor && props.copy){
    headerText = "Copy Visitor";
    state.value.Firstname = "";
    state.value.Lastname = "";
    state.value.Email = "";
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    v$.value.$reset();
    state.value.Firstname = "";
    state.value.Lastname = "";
    postalcode.value = "";
    street.value = "";
    city.value = "";
    state.value.Email = "";
    company.value = "";
    state.value.Country = "";
    substitutes.value = [];
    access.value = AccessLevel.DEPARTMENT;
    props.onHide(true);
  }

})

watch(userQuery, async () => {
  delay(userQueryTimer, async () => {
    if (!userQuery.value) {
      usersFound.value = [];
      return;
    }

    let query = userQuery.value;
    usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

const selectUser = () => {
  substitutes.value.push(userQuery.value);
  userQuery.value = '';
}

async function dispatchEditVisitorAsync(EditVisitorRequest: EditVisitorRequest) {
  await v$.value.$reset();
  await v$.value.$validate(); // checks all inputs
  if (v$.value.$error) {
    return;
  }
  await VisitorService.putApiV1VisitorEdit(EditVisitorRequest);
  dialogVisible.value = false;
}

async function dispatchCreateVisitorAsync(createVisitorRequest: CreateVisitorRequest) {
  console.log("Visitor Create: ", createVisitorRequest)
  await v$.value.$reset();
  await v$.value.$validate() // checks all inputs
  if (v$.value.$error) {
    return;
  }
  await VisitorService.postApiV1VisitorCreate(createVisitorRequest)
  dialogVisible.value = false;
}

// function getCountries(lang = 'en') {
//     const A = 65
//     const Z = 90
//     const countryName = new Intl.DisplayNames([lang], { type: 'region' });
//     const countries: any[] = []
//     for(let i=A; i<=Z; ++i) {
//         for(let j=A; j<=Z; ++j) {
//             let code = String.fromCharCode(i) + String.fromCharCode(j)
//             let name = countryName.of(code)
//             if (code !== name ) { //&& !countries.some(e => e.name === name)) {
//                 countries.push({code: code, name: name });
//             }
//         }
//     }
//     return countries.sort((p1,p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0);
// }

function getCountriesList() {
  const result: any[] = []
  const countryCodes = Object.keys(countries);
  countryCodes.map(code => result.push({ code: code, name: (countries as any)[code].name }));
  return result.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0);
}


</script>
<style scoped>
.input-invalid {
    border: 1px solid var(--primary-color);
}

.input-invalid:hover {
    border: 1px solid var(--primary-color) !important;
}
span {
    color: var(--primary-color);
    font-weight: 600;
}
</style>
